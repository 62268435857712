.view-my-card-card-container {
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    &-title {
      font-size: 20px;
      font-weight: 700;
      color: #000000;
    }

    &-nav-buttons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    
    &-nav-button {
     cursor: pointer;
    }
  }

  &__card-wrapper {
    position: relative;
    height: 14rem;
    margin-bottom: 1.5rem;
    overflow: hidden;
    width: 100%;

    &-card-slider {
      display: flex;
      transition: transform 0.3s ease;
      gap: 25px;
    }
  }

  &__balance-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    color: #3B4857;


    &-label {
      font-size: 14px;
      font-weight: 400;
    }

    &-amount {
      font-size: 30px;
      font-weight: 700;
      
    } 
  }

  &__action-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    &-button {
      background-color: #3b82f6;
      color: white;
      padding: 0.75rem 1rem;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s;
      border: none;
      cursor: pointer;
      
      &:hover {
        background-color: #2563eb;
      }
    }

    &-icon {
      margin-right: 0.5rem;
    }
    .disabled-button {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  &__top-wrapper  {
    padding: 48px;
    padding-bottom: 0px;
  }

  &__bottom-wrapper  {
    min-height: 460px;
    background-color: #FFFFFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 24px 32px;
    margin: 0px 16px;
  }
}
