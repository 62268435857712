.banner-modal {
  position: relative;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 20px 48px;
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;

  &__close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 17px;
  }

  &__title {
    color: #3B4857;
    font-size: 20px;
    font-weight: 600;
  }

  &__text {
    color: #525252;
    font-size: 16;
    font-weight: 400;
  }

  &__button {
    height: 48px;
    color: #1D39C4;
    background: #D6E4FF;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    &:hover {
      background: #D6E4FF;
      color: #1D39C4;
      opacity: 0.8;
    }
  }
}