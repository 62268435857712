.resend-code {
  display: flex;
  flex-direction: row;
  > span {
      font-size: 12px;
      font-weight: 400;
  };
  &__timer {
    color: grey;
    margin-left: 5px;
  }
  &__button {
    cursor: pointer;
    margin-left: 5px;
    color: #2988EF;
    font-size: 12px;
    font-weight: 400;
  }
}