.transaction-list {
  width: 100%;
  padding-top: 48px;
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    &-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
    }

    &-filters {
      display: flex;
      gap: 16px;

      &-filter {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border: 1px solid #E0E6ED;
        border-radius: 100px;
      }
    }
  }

    &__list {
    display: flex;
    flex-direction: column;
    gap: 12px
  }
}