.setting-input {
  display: flex;
  height: 56px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 12px;
  background: rgba(13, 117, 220, 0.10);
  color: #3B4857;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}