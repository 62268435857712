.card {
  &__title {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
  }

  &__body {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3px;
      color: #818A98;
      font-size: 14px;
      font-weight: 400;


      &-subtitle {
        color: #3B4857;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}