.add-card {
  flex: 0 0 calc(90% - 42px);
  max-width: 330px;
  background-color: #f3f4f6;
  border: 2px dashed #d1d5db;
  border-radius: 12px;
  padding: 20px;
  height: 211px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;

  &__content {
    color: #3B4857;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }
  
  &__icon-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #3B4857;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &__text {
    font-size: 16px;
    font-weight: 700;
  }
}

