.my-cards-page {
  display: flex;
  justify-content: space-between;
  // justify-content: center;
  flex: 1 0 auto;
  gap: 20px;
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  &__left {
    width: 100%;
    max-width: 510px;
    // margin: 0 auto;
    padding-bottom: 0px;
    background-color: #f3f4f6;
    border-radius: 1.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  &__right {
    width: 100%;
    max-width: 720px;
  }
  // @media screen and (max-width: 1023px) {
  //   gap: unset;
  //   &__left, &__right {
  //     max-width: unset;
  //   }
  //   &__right {
  //     margin-top: 20px;
  //   }
  // }
}
