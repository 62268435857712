.change-pin {
  display: flex;
  flex-direction: column;
  &__title {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 600;
    color: #273443;
  }

  &__input-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    &__action-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 10px;
      border: 1px solid #2988EF;
      cursor: pointer;
    }
  }
.pincode-container{
  display: flex;
  gap: 32px;
  .pincode-input {
    text-align: center;
    width: 42px;
    height: 42px;
    font-size: 18px;
    font-weight: 900;
    border: 1px solid #E0E6ED;
    color:#3B4857;
    border-radius: 4px;
    &:focus {
      outline: none;
    }
  }
}
}