.credit-card {
  position: relative;
  flex: 0 0 calc(90% - 42px);
  max-width: 330px;
  background-color: black;
  border-radius: 12px;
  padding: 20px;
  height: 211px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.25), 10px 2px 10px rgba(0, 0, 0, 0.22);

  &--rotated {
    transform: perspective(5000px) rotateX(310deg) rotateY(348deg);
    margin: 0 auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__name {
    font-size: 14px;
    font-weight: 500;
  }

&__footer {
  display: flex;
  justify-content: space-between;
  &-number {
    font-size: 14px;
    font-weight: 600;
    
    // margin-bottom: 0.5rem;
  }

  &-expiry {
    font-size: 12px
  }
}
  &__overlay {
    max-width: 330px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    gap: 12px;

    &-icon {
      // font-size: 24px;
      // margin-bottom: 8px;
    }
    &-text {
      font-size: 20px;
      font-weight: 400;
      text-align: center;     
    }
  }
}