.card-setting {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__checkbox {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #000000E0;
    margin-top: 16px;
  }
}