.verification-modal {
  font-weight: 400;
  
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 80px;
  }
  &__title {
    font-size: 14px;
    color: #3B4857;
    margin-bottom: 6px;
  }
  &__item {
   
    color: #5F6E80;
    margin-bottom: 6px;
    
    label {
      font-size: 12px;
    }
    
    &__contaiter {
      display: flex;
      gap: 32px;
      margin-top: 2px;
    }

    &__input {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #E0E6ED;

    }
  }
  &__button {
    display: flex;
    height: 40px;
    padding: 14px 48px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
   
  }

  .ant-modal-body {
    padding: 32px 20px;
  }
  .ant-modal-content {
    border-radius: 10px;
    width: 370px;
    height: 300px;
  }
}