.block-card {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: black;
  &__title {
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-weight: 400;
    margin-bottom: 24px;
    color: #818A98;
  }

  &__button {
    width: 100%;
    display: flex;
    height: 40px;
    padding: 14px 48px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: #FFF;
    font-weight: 600;
    border-radius: 8px;
    background: #E85B48;
    &:hover {
      background: #E85B48 !important;
      opacity: 0.8;
    }
  }
}