.detailed-transaction {
  width: 100%;
  max-width: 480px;
  padding-top: 65px;
  position: relative;
  margin: 0 auto;

  &__back {
    position: absolute;
    left: 0;
    top: 65px;
    cursor: pointer;
  }
  &__header {
    display: flex;
    justify-content: center;
    margin-bottom: 34px;
    &-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 12px;

      &-amount {
        font-size: 24px;
        font-weight: 600;

        &_get {
          color: #22b84c;
        
        }
        &_sent {
          color: black;
          
        }
        &_failed, &_error {
          color: #FF4D4F;
        
        }

      }
      &-type {
        font-size: 14px;
        font-weight: 400;
        background: #d7e1f6;
        display: flex;
        border-radius: 4px;
        padding: 5px 8px;
        color: #2F54EB;
       
        &_failed, &_error {
          color: #FF4D4F;
        
        }
      }
    }
  }
 
  &__body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &-item {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #E0E6ED;
      gap: 5px;
      color: #292929;
      font-weight: 600;
      
      &-subtitle {
        font-size: 16px;
        color: #525252;
        font-weight: 400;
      }
    }
    &-buttons {
      display: flex;
      gap: 17px;
      &-button {
        width: 228px;
        background-color: #F5F8F7;
        height: 40px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid #4D7F70;
        cursor: pointer;
      }
    }
  }

}