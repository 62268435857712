.card-transaction {
  width: 100%;
  border: 1px solid #E0E6ED;
  border-radius: 10px;
  padding: 0px 20px;
  background: #F8FAFE;
  cursor: pointer;
  @media screen and (max-width: 1091px) {
    padding: 0 10px;
  }
  &:hover {
    background: #E3E9F6;
    border-color: #2A92FF;
  }
  > .ant-row {
    height: 72px;
  }

  &__logo {
   
  }
  &__name {
    padding-left: 12px;
    display: flex;
    gap: 4px;
    flex-direction: column;
    font-size: 14px;
    color: #818A98;
    font-weight: 600;

    &-type {
      font-weight: 500;
      color: #3B4857;
    }
  }
 

  &__detailed {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 14px;

    &-date {
      font-weight:400;
      color: #818A98;
    }

    &-amount {
      font-weight: 700;
    
      &_get {
        color: #22b84c;
      
      }
      &_sent {
        color: black;
        
      }
      &_failed, &_error {
        color: #FF4D4F;
      
      }
    }
  }
 
}
